import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import { Dropzone } from 'src/components/IMUI';

@connect(null, { showNotification })
export default class DropzoneWithErrorHandler extends React.PureComponent {
  static propTypes = {
    ...Dropzone?.propTypes,
    showNotification: PropTypes.func,
    style: PropTypes.object,
    notificationError: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      level: PropTypes.string,
      position: PropTypes.string,
      acceptType: PropTypes.string,
    }),
  };
  render() {
    const {
      showNotification: _,
      notificationError: __,
      acceptType: ___,
      ...rest
    } = this.props;
    return (
      <Dropzone
        {...rest}
        onDropRejected={() =>
          this.props.showNotification({
            title: this.props.notificationError?.title || 'File not accepted',
            message:
              this.props.notificationError?.message ||
              `Please ensure that the document format is valid and the size does not exceed the limit (< 25mb). Only ${
                this.props.acceptType || this.props.accept
              } are supported`,
            level: this.props.notificationError?.level || 'error',
            position: this.props.notificationError?.position || 'br',
          })
        }
      />
    );
  }
}
