import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Actions,
  AutoCompleteField,
  Button,
  Container,
  DatePicker,
  MAX_UPLOAD_SIZE,
  Section,
  SelectField,
  TextField,
  Toggle,
} from 'src/components/IMUI';
import reportTypes from 'src/data/reportTypes.json';
import { getText } from 'src/services/DictionaryService';
import createValidator from 'src/utils/validation';
import { Icon } from 'im/ui/Icon';
import cls from './UploadReportForm.module.css';
import { canAutoCode, canManageReport } from 'src/userStorage';
import historyInstance from 'src/historyInstance';
const FILETYPE_ACCEPT = '.docx,.pdf';
const LINE2 =
  'Or click if you prefer to select a file from your computer to be uploaded';
const LINE3 = 'Your document size cannot exceed the limit of 25 mb';

const DropzoneDocx = (notification, change) => (field) => (
  <>
    <Dropzone
      name={field.name}
      accept={FILETYPE_ACCEPT}
      onDrop={(accepted, rejected) => {
        if (rejected.length > 0)
          return notification({
            title: 'File not accepted',
            message:
              'Please ensure that the document format is valid and the size does not exceed the limit (< 25mb)',
            level: 'error',
          });
        if (accepted.length > 0) {
          change?.(
            'name',
            accepted?.[0]?.name.replace('.pdf', '').replace('.docx', '')
          );
          return field.input.onChange(accepted);
        }
      }}
      className={cls.filedrop}
      style={{
        backgroundColor: Array.isArray(field?.input?.value)
          ? '#aed581'
          : '#b2ebf2',
      }}
      multiple={false}
      maxSize={MAX_UPLOAD_SIZE}
    >
      {Array.isArray(field?.input?.value) ? (
        <ul className={cls.filelist}>
          {field.input.value.map((file, i) => (
            <li key={i}>{file.name}</li>
          ))}
        </ul>
      ) : (
        <div>
          <Icon name="upload" className={cls.uploadIcon} />
          <span>
            Start by dragging here some{' '}
            <strong>Word document (.docx) or some PDF file (.pdf)</strong>
          </span>
          <br />
          <span>{LINE2}</span>
          <br />
          <span>{LINE3}</span>
        </div>
      )}
    </Dropzone>
    {field.meta.touched && field.meta.error && (
      <span className={cls.error}>{field.meta.error}</span>
    )}
    <br />
  </>
);

class UploadReport extends React.PureComponent {
  static propTypes = {
    grantees: PropTypes.array,
    grants: PropTypes.array,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool.isRequired,
    regions: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    autocode: PropTypes.bool,
    grant_id: PropTypes.any,
    grantee_id: PropTypes.any,
    project_id: PropTypes.any,
    change: PropTypes.func,
    noGrantAndGrantee: PropTypes.bool,
    name: PropTypes.string,
  };

  renderGrantAndGranteeInputs(filteredGrants, filteredGrantees) {
    return (
      <FlexRow>
        <FlexColumn size={1 / 2}>
          <Field name="grantee_id" component="input" type="hidden" />
          <Field
            fullWidth
            component={AutoCompleteField}
            name="grantee"
            hintText={`Enter ${getText('grantee')} name`}
            dataSource={filteredGrantees}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            onNewRequest={(item) => {
              this.props.change('grantee_id', item?.id);
            }}
            onUpdateInput={() => {
              this.props.change('grantee_id', '');
            }}
            menuStyle={{ maxHeight: '30vh' }}
            openOnFocus
            label={getText('Grantee')}
          />
        </FlexColumn>
        <FlexColumn size={1 / 2}>
          <Field name="grant_id" component="input" type="hidden" />
          <Field
            fullWidth
            component={AutoCompleteField}
            name="grant"
            hintText={`Enter ${getText('grant')} name`}
            dataSource={filteredGrants}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            onNewRequest={(item) => {
              this.props.change('grant_id', item?.id);
            }}
            onUpdateInput={() => {
              this.props.change('grant_id', '');
            }}
            menuStyle={{ maxHeight: '30vh' }}
            openOnFocus
            label={getText('Grant')}
          />
        </FlexColumn>
      </FlexRow>
    );
  }

  notEnoughDataToUpload() {
    return (
      <Container
        centered
        style={{ justifyContent: 'center', marginBottom: 32, padding: 40 }}
        className={cls.filedrop}
      >
        <p style={{ color: '#00000099' }}>
          No Grantees or Grants have been added to this project.
          <br />
          Please add them first before importing reports
        </p>
        <div>
          <Button
            style={{ color: '#FFFFFF' }}
            size="l"
            label="Go to Sample"
            onClick={() =>
              historyInstance.push(`/analysis/${this.props.project_id}/edit`)
            }
          />
        </div>
      </Container>
    );
  }

  render() {
    const filteredGrants = this.props.grantee_id
      ? this.props.grants.filter(
          (g) =>
            g.grantee_id == this.props.grantee_id ||
            g.grantee_id == this.props.grantee_id
        )
      : this.props.grants;
    const filteredGrantees = this.props.grant_id
      ? this.props.grantees.filter((h) =>
          h?.grants.some((g) => g.id == this.props.grant_id)
        )
      : this.props.grantees;
    const notEnoughDataToUpload =
      !this.props.noGrantAndGrantee &&
      filteredGrants.length === 0 &&
      filteredGrantees.length === 0;
    if (notEnoughDataToUpload) return this.notEnoughDataToUpload();

    const fillChangeName =
      this.props.name?.length > 0 ? () => null : this.props.change;
    const PERIOD_TIP =
      'Here you can add an optional time period for over-time comparison (e.g., a year or quarter) which will show up in charts or on filters';
    const CODE_TIP =
      'Here you can add an optional code to easily identify your report';
    const AUTOCODE_TIP =
      'Based on your current tag groups, tags and previous selections, we will bring tagging suggestions';

    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)} noValidate>
        <Section>
          {!this.props.editMode && (
            <Field
              name="file"
              component={DropzoneDocx(
                this.props.showNotification,
                fillChangeName
              )}
            />
          )}
          {!this.props.noGrantAndGrantee &&
            this.renderGrantAndGranteeInputs(filteredGrants, filteredGrantees)}
          <FlexRow>
            <FlexColumn size={1 / 1}>
              <Field
                name="name"
                component={TextField}
                label="Report name"
                fullWidth
              />
            </FlexColumn>
          </FlexRow>
        </Section>

        <Section>
          <FlexRow>
            <FlexColumn size={1 / 3}>
              <Field
                fullWidth
                name="date"
                component={DatePicker}
                label="Report date"
                format={(value) => (value === '' ? null : value)}
              />
            </FlexColumn>
            <FlexColumn size={1 / 3}>
              <Field
                fullWidth
                name="period"
                component={TextField}
                label={
                  <span>
                    Period{' '}
                    <Icon
                      name="question-filled"
                      tip={PERIOD_TIP}
                      color="#626264"
                    />
                  </span>
                }
                format={(value) => (value == '' ? null : value)}
              />
            </FlexColumn>
            <FlexColumn size={1 / 3}>
              <Field
                fullWidth
                name="code"
                component={TextField}
                label={
                  <span>
                    Code{' '}
                    <Icon
                      name="question-filled"
                      tip={CODE_TIP}
                      color="#626264"
                    />
                  </span>
                }
                format={(value) => (value == '' ? null : value)}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn size={1 / 3}>
              <Field
                fullWidth
                component={SelectField}
                label="Report Type"
                name="report_type"
                value={this.state?.reportType}
              >
                {reportTypes.map((el, i) => (
                  <MenuItem key={i} value={el.value} primaryText={el.text} />
                ))}
              </Field>
            </FlexColumn>
            <FlexColumn size={1 / 3}>
              <Field
                fullWidth
                component={SelectField}
                label="Global Region"
                name="global_region"
                value={this.state?.globalRegion}
              >
                {Object.keys(this.props.regions).map((el, i) => (
                  <MenuItem key={i} value={el} primaryText={el} />
                ))}
              </Field>
            </FlexColumn>

            {/* TODO: Trigger AI::TaggingRequest after parsing document
            {/* {canAutoCode() && ( */}
            {/*   <FlexColumn size={1 / 3}> */}
            {/*     <span */}
            {/*       style={{ float: 'right', marginTop: 36, marginBottom: -28 }} */}
            {/*     > */}
            {/*       <Icon name="question-filled" tip={AUTOCODE_TIP} /> */}
            {/*     </span> */}
            {/*     <Toggle */}
            {/*       label="AI autotagging" */}
            {/*       labelPosition="right" */}
            {/*       toggled={this.props.autocode} */}
            {/*       onToggle={(_, toggled) => */}
            {/*         this.props.change('autocode', toggled) */}
            {/*       } */}
            {/*       style={{ margin: '16px 0 0 0' }} */}
            {/*     /> */}
            {/*   </FlexColumn> */}
            {/* )} */}
          </FlexRow>
        </Section>

        <br />
        <br />
        <Container horizontal>
          <Actions>
            <Button
              size="l"
              negative
              label="Cancel"
              disabled={this.props.submitting}
              onClick={this.props.handleClose}
            />
          </Actions>
          <Actions>
            <Button
              disabled={!canManageReport() || this.props.submitting}
              size="l"
              type="submit"
              label={this.props.editMode ? 'Update' : 'Upload'}
            />
          </Actions>
        </Container>
      </form>
    );
  }
}

function validate(values, props) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const minLength = (n) => (v) => v.length >= n;
  return createValidator({
    name: [
      [not(isDefined), () => 'Report name is required'],
      [not(minLength(3)), () => 'Report name must have at least 3 characters'],
    ],
    file: [[not(isDefined), () => 'File is required']],
    grantee: props.noGrantAndGrantee
      ? []
      : [
          [not(isDefined), () => getText('Grantee name is required')],
          [
            () => !props.grantees.some((g) => g.id == values.grantee_id),
            () => getText('Grantee not recognized, use dropdown to select one'),
          ],
        ],
    grant: props.noGrantAndGrantee
      ? []
      : [
          [not(isDefined), () => getText('Grant name is required')],
          [
            () => !props.grants.some((g) => g.id == values.grant_id),
            () => getText('Grant not recognized, use dropdown to select one'),
          ],
        ],
  })(values);
}

export const FORM_NAME = 'uploadReportForm';
export default connect(
  (state) => ({
    name: formValueSelector(FORM_NAME)(state, 'name'),
    grantee: formValueSelector(FORM_NAME)(state, 'grantee'),
    grantee_id: formValueSelector(FORM_NAME)(state, 'grantee_id'),
    autocode: formValueSelector(FORM_NAME)(state, 'autocode'),
    grant: formValueSelector(FORM_NAME)(state, 'grant'),
    grant_id: formValueSelector(FORM_NAME)(state, 'grant_id'),
    reportType: formValueSelector(FORM_NAME)(state, 'reportType'),
  }),
  { showNotification }
)(
  reduxForm({ form: FORM_NAME, validate, initialValues: { date: new Date() } })(
    UploadReport
  )
);
