import assocPath from 'ramda/src/assocPath';
import clone from 'ramda/src/clone';
import { v1 as uuidv1 } from 'uuid';
import { SurveyRespondents } from 'src/api/SurveyRespondents';
import {
  SURVEY__GET_REQUEST,
  SURVEY__GET_SUCCESS,
  SURVEY__GET_FAILURE,
  SURVEY__ADD_QUESTION,
  SURVEY__MOVE_QUESTION,
  SURVEY__QUESTION_CHANGE_PROPERTY,
  SURVEY__CHANGE_PROPERTY,
  SURVEY__CHANGE_NAME,
  SURVEY__QUESTION_CHANGE_I18N,
  SURVEY__QUESTION_CHANGE_OPTIONS_I18N,
  SURVEY__QUESTION_DELETE,
  SURVEY__QUESTION_CLONE,
  SURVEY__RESET,
  SURVEY__SAVE_REQUEST,
  SURVEY__SAVE_SUCCESS,
  SURVEY__ADD_QUESTION_TAG,
  SURVEY__REMOVE_QUESTION_TAG,
  SURVEY__DELETE_SUCCESS,
  SURVEY__UPDATE_QUESTION,
  SURVEY__GRANTEES_DELETE_SUCCESS,
} from 'src/constants';
import {
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  FINANCIAL,
  SINGLE_MATRIX,
} from 'src/data/questionTypes';
import { toSurveyFromApi } from 'src/serializers/surveySerializer';
const initialState = {
  id: null,
  pending: false,
  name: null,
  dirty: false,
  saving: false,
  public: true,
  questions: [],
  languages: ['en'],
  activeLanguage: 'en',
  i18n: {},
};

export default function surveyReducer(
  state = initialState,
  { type, payload, meta = {} }
) {
  switch (type) {
    case SURVEY__ADD_QUESTION: {
      const newState = { ...clone(state), dirty: true };
      const question = {
        id: uuidv1(),
        type: payload.type,
        isLayout: payload.isLayout,
        required: false,
        tags: [],
        i18n: {},
        conditional_logic: [],
        multiTags: false,
      };
      question.settings = {};
      if (
        question.type === SINGLE_CHOICE ||
        question.type === MULTIPLE_CHOICE
      ) {
        question.options = [];
      }
      if (question.type === FINANCIAL && !question.currency)
        question.currency = 'USD';
      newState.questions.splice(payload.position, 0, question);
      return newState;
    }

    case SURVEY__MOVE_QUESTION: {
      const newState = { ...clone(state), dirty: true };
      const question = newState.questions[payload.currentIndex];
      newState.questions.splice(payload.currentIndex, 1);
      newState.questions.splice(payload.newIndex, 0, question);
      return newState;
    }

    case SURVEY__QUESTION_CHANGE_PROPERTY: {
      const newState = { ...clone(state), dirty: !meta.silent };
      const questionIndex = newState.questions.findIndex(
        (q) => q.id == payload.questionId
      );
      if (questionIndex === -1) return state;

      const question = assocPath(
        Array.isArray(payload.propertyName)
          ? payload.propertyName
          : [payload.propertyName],
        payload.propertyValue,
        newState.questions[questionIndex]
      );
      newState.questions.splice(questionIndex, 1, question);
      return newState;
    }

    case SURVEY__UPDATE_QUESTION: {
      const newState = { ...clone(state), dirty: !meta.silent };
      const questionIndex = newState.questions.findIndex(
        (q) => q.id == payload.questionId
      );
      if (questionIndex === -1) return state;

      newState.questions[questionIndex] = payload.newQuestion;
      return newState;
    }

    case SURVEY__CHANGE_PROPERTY: {
      const newState = { ...clone(state), dirty: !meta.silent };
      newState[payload.name] = payload.value;
      return newState;
    }

    case SURVEY__CHANGE_NAME: {
      const newState = { ...clone(state), dirty: true };
      const lang =
        payload.langCode || state.activeLanguage || state.languages[0];
      newState.name = lang === state.languages[0] ? payload.name : state.name;
      newState.i18n = newState.i18n || {};
      newState.i18n[lang] = newState.i18n[lang] || {};
      newState.i18n[lang].name = payload.name;
      return newState;
    }

    case SURVEY__QUESTION_CHANGE_I18N: {
      const newState = { ...clone(state), dirty: true };
      const lang =
        payload.langCode || state.activeLanguage || state.languages[0];
      const questionIndex = state.questions.findIndex(
        (q) => q.id == payload.question.id
      );
      if (questionIndex === -1) return state;

      const isDefaultLanguage = lang == state.languages[0];
      if (isDefaultLanguage) {
        const i18nPath = payload.altPath || payload.path;
        newState.questions[questionIndex] = assocPath(
          Array.isArray(i18nPath) ? i18nPath : [i18nPath],
          payload.value,
          payload.question
        );
      }

      let questionDict = clone(payload.question.i18n[lang]) ?? {};
      questionDict = assocPath(
        Array.isArray(payload.path) ? payload.path : [payload.path],
        payload.value,
        questionDict
      );
      newState.questions[questionIndex].i18n = {
        ...newState.questions[questionIndex].i18n,
        [lang]: questionDict,
      };
      return newState;
    }

    case SURVEY__QUESTION_CHANGE_OPTIONS_I18N: {
      const newState = { ...clone(state), dirty: true };

      const questionIndex = state.questions.findIndex(
        (q) => q.id == payload.question.id
      );
      if (questionIndex === -1) return state;

      let translations = clone(payload.question.i18n) ?? {};
      const i18nDict = Object.keys(translations).reduce((dict, langCode) => {
        dict[langCode] = payload.values[langCode];
        return dict;
      }, {});

      newState.questions[questionIndex].i18n = i18nDict;
      return newState;
    }

    case SURVEY__QUESTION_DELETE: {
      const newState = { ...clone(state), dirty: true };
      const questionIndex = newState.questions.findIndex(
        (q) => q.id == payload
      );
      if (questionIndex === -1) return state;

      newState.questions.splice(questionIndex, 1);
      return newState;
    }

    case SURVEY__QUESTION_CLONE: {
      const newQuestionTitle = `Copy of ${payload.question.question}`;
      const newQuestion = {
        id: uuidv1(),
        question: newQuestionTitle,
        isLayout: false,
        description: payload.question.description,
        type: payload.question.type,
        required: payload.question.required,
        tags: payload.question.tags,
        i18n: clone(payload.question.i18n),
        conditional_logic: payload.question.conditional_logic,
        multiTags: payload.question.multiTags,
        position: payload.position + 1,
        settings: clone(payload.question.settings),
        hasOther: payload.question.hasOther,
        other_tag_id: payload.question.other_tag_id,
        maxSelections: payload.question.maxSelections,
        currency: payload.question.currency,
      };
      Object.values(newQuestion.i18n)[0].question = newQuestionTitle;
      if (
        newQuestion.type === SINGLE_CHOICE ||
        newQuestion.type === MULTIPLE_CHOICE
      ) {
        const oldToNew = {};
        newQuestion.options = payload.question.options.map((option) => {
          const newId = uuidv1();
          oldToNew[option.id] = newId;
          return { ...option, id: newId };
        });
        Object.entries(newQuestion.i18n).forEach(([langKey, values]) => {
          const newi18nObj = {};
          Object.entries(values).forEach(([key, value]) => {
            if (oldToNew[key]) {
              newi18nObj[oldToNew[key]] = value;
            } else {
              newi18nObj[key] = value;
            }
          });
          newQuestion.i18n[langKey] = newi18nObj;
        });
      }
      if (newQuestion.type === SINGLE_MATRIX) {
        newQuestion.matrixQuestionType = payload.question.matrixQuestionType;
        newQuestion.matrixReachType = payload.question.matrixReachType;
        newQuestion.matrixValues = [...payload.question.matrixValues];
      }
      const newState = { ...clone(state), dirty: true };
      newState.questions.splice(payload.position + 1, 0, newQuestion);
      return newState;
    }

    case SURVEY__GET_REQUEST:
      return { ...clone(state), pending: !meta.silent };

    case SURVEY__GET_FAILURE:
      return { ...clone(state), pending: false };

    case SURVEY__SAVE_REQUEST:
      return { ...clone(state), saving: true };

    case SURVEY__GET_SUCCESS: {
      const survey = toSurveyFromApi(payload.data);
      const languages =
        survey.languages ||
        (state.languages?.length > 0
          ? state.languages
          : initialState.languages);
      return {
        ...clone(state),
        ...survey,
        languages,
        activeLanguage: languages[0],
        dirty: false,
        pending: false,
      };
    }

    case SURVEY__SAVE_SUCCESS:
      const languages =
        state.languages?.length > 0 ? state.languages : initialState.languages;
      return {
        ...clone(state),
        ...toSurveyFromApi(payload.data),
        languages,
        dirty: false,
        saving: false,
      };

    case SURVEY__ADD_QUESTION_TAG: {
      const newState = { ...clone(state), dirty: true };
      const question = newState.questions.find(
        (q) => q.id == payload.questionId
      );
      if (!question) return state;
      question.tags ||= [];
      if (question.tags?.includes(payload.tagId)) return state;
      question.tags.push(payload.tagId);
      return newState;
    }

    case SURVEY__REMOVE_QUESTION_TAG: {
      const newState = { ...clone(state), dirty: true };
      const question = newState.questions.find(
        (q) => q.id == payload.questionId
      );
      if (!question) return state;
      question.tags ||= [];
      if (!question.tags?.includes(payload.tagId)) return state;
      question.tags.splice(
        question.tags.findIndex((t) => t == payload.tagId),
        1
      );
      return newState;
    }

    case SurveyRespondents.actionTypes.destroy.success: {
      if (!meta?.notified) return state;
      return {
        ...clone(state),
        sent_surveys_count: state.sent_surveys_count - 1,
      };
    }

    case SurveyRespondents.actionTypes.deliver.success:
      return {
        ...clone(state),
        sent_surveys_count: state.sent_surveys_count + 1,
      };

    // we do not delete respondent anymore
    case SURVEY__GRANTEES_DELETE_SUCCESS:
      if (meta?.granteeSurvey?.is_finished) {
        return {
          ...clone(state),
          finished_surveys_count: state.finished_surveys_count - 1,
        };
      }
      if (
        meta?.granteeSurvey?.is_notified &&
        !meta?.granteeSurvey?.public_submission &&
        !meta?.granteeSurvey?.anonymous
      ) {
        return {
          ...clone(state),
          sent_surveys_count: state.sent_surveys_count - 1,
        };
      }
      return state;

    case SURVEY__RESET:
      return { ...initialState };

    case SURVEY__DELETE_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}
