import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import Chart from 'src/components/Chart/Chart';
import { getText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';
import { prepareData } from 'src/components/Chart/chartUtils';
import { formatValue, formatValueLabel } from '../helpers/formatters';
import {
  transformChartToStacked,
  transformChartToHeatmap,
  transformChartToBubble,
} from '../helpers/transformers';
import ChartTypeIcon from './ChartTypeIcon';
import { getAxisId } from '../../Surveys/Survey/Summary/surveyAnalysisUtils';
const isNumeric = (v) => v != null && !isNaN(v) && isFinite(Number(v));
const flattenSingle = (value) =>
  String(
    value?.category && value?.values?.[0]
      ? `${value.category}___${value.values?.[0]}`
      : value?.values?.[0] ?? value
  )
    .trim()
    .replace('___', ' > ');

@connect(pick(['chartsAxisData']))
export default class DataChart extends React.PureComponent {
  static propTypes = {
    chartsAxisData: PropTypes.object,
    settings: PropTypes.object,
    chartType: ChartTypeIcon.propTypes.type,
    calculationType: PropTypes.array,
  };
  static defaultProps = { settings: {} };

  getChartTypeForSeries(valueType, calculationType, type) {
    const percCalculations = calculationType.filter(
      (calcType) => calcType?.indexOf('percentage') !== -1
    ).length;
    if (
      valueType === 'funding_total' &&
      percCalculations > 0 &&
      percCalculations < calculationType.length
    ) {
      return type;
    }
    return (valueType === 'funding_total' ||
      valueType?.indexOf('percentage') !== -1) &&
      calculationType.length > 1
      ? 'line'
      : type;
  }

  prepareSerieForValueType = (valueType, name, type, calculationType) => ({
    valueType,
    name: name,
    type: this.getChartTypeForSeries(valueType, calculationType, type),
    data: [],
    yAxis: getAxisId(valueType),
    visible: true,
    tooltip: {
      pointFormatter: function () {
        return `<span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${formatValueLabel(this.y, valueType)}${
          this.series.chart.options.dataLabelSuffix || ''
        }</b><br/>`;
      },
    },
  });
  // linear, logarithmic, datetime or category
  getAxisType = (categories, attribute_name) => {
    if (!categories?.length) return 'category';
    if (categories.every(isNumeric)) return 'linear';
    if (attribute_name === 'title') return 'category';
    if (attribute_name === 'country_code') return 'category';
    if (attribute_name === 'amount') return 'linear';
    return 'category';
  };

  translateName = (name, attribute_name) => {
    if (attribute_name === 'country_code')
      return getCountryNameByCode(name) || name || 'Not Provided';
    if (attribute_name === 'amount')
      return formatValueLabel(Number(name), 'currency');
    return name;
  };

  getConfig() {
    const { calculationType, settings } = this.props;
    const _data = this.props.chartsAxisData?.data || [{}];
    const _meta = this.props.chartsAxisData?.meta || {
      x: { attribute_values: [] },
      y: { attribute_values: [] },
    };
    const { raw, filteredData, meta, isXY, flatten, flattenValue } =
      prepareData(_data, _meta, settings, calculationType);
    const [type, typeStacking] = this.props.chartType.split(':');
    const isCSV = meta.x.model === 'CSV';
    const series = [];
    const yAxis = [];
    const stack = typeStacking || settings.stackChartSeries;
    if (['bubble', 'line', 'heatmap', 'pie'].includes(this.props.chartType))
      settings.stackChartSeries = false; // force false for stack in those types

    const types = [...new Set(calculationType.map(getAxisId))];
    const noCommon = types.includes(undefined);
    if (noCommon)
      yAxis.push({
        id: 'count',
        title: { text: isCSV ? calculationType[0] : 'Count' },
        allowDecimals: false,
        labels: {
          formatter() {
            return `${formatValueLabel(
              this.value,
              'count',
              `${Math.round(this.value)}`.length > 6,
              this.chart.options.dataLabelSuffix
            )}`;
          },
        },
      });
    if (types.includes('money'))
      yAxis.push({
        id: 'money',
        title: { text: 'Funding' },
        labels: {
          formatter() {
            return `${formatValueLabel(this.value, 'funding_total', true)}`;
          },
        },
        opposite: types.length > 1,
        visible: true,
        min: 0,
      });
    if (types.includes('reach'))
      yAxis.push({
        id: 'reach',
        title: { text: 'Reach value' },
        labels: {
          formatter() {
            return `${formatValueLabel(this.value, 'reach', true)}`;
          },
        },
        opposite: types.length > 1,
        visible: true,
        min: 0,
      });
    if (types.includes('percentage'))
      yAxis.push({
        id: 'percentage',
        title: { text: 'Percent' },
        labels: {
          formatter() {
            return `${formatValueLabel(this.value, 'percentage')}`;
          },
        },
        opposite: types.length > 1,
        visible: true,
        min: 0,
      });

    const formatterFn = function () {
      return this.y == 0
        ? null
        : `<span>${
            settings.showTitleLabels ? this.key : ''
          }<br><b> <span>${formatValueLabel(
            this.y,
            this.point?.series?.userOptions?.valueType,
            this.point?.series?.userOptions?.valueType === 'funding_total' &&
              this.y > 100,
            this.series?.chart?.options?.dataLabelSuffix
          )}</span></b></span>`;
    };
    const formatPieFn = function () {
      return settings.hideEmpty && this.y <= 0
        ? null
        : `<b>${
            this.key
          }</b> <br> <span style="font-size: 1rem">   <span>${formatValueLabel(
            this.y || 0,
            calculationType[0],
            true,
            this.series?.chart?.options?.dataLabelSuffix
          )}</span> &ensp;&ensp; <div style="color:#666667;font-size:0.85rem;text-align:right;">${formatValueLabel(
            this.percentage
          )}%</span></div>`;
    };

    if (calculationType.length > 1)
      series.sort((s) => (s.type == 'line' ? 1 : 0));

    const categories = meta.x.attribute_values.map((val) =>
      this.translateName(getText(flattenValue(val)), meta.x.attribute_name)
    );

    const config = {
      title: { text: '' },
      chart: { type },
      plotOptions: {
        series: {
          stacking: stack
            ? settings.stackingPercent
              ? 'percent'
              : 'normal'
            : false,
        },
        column: {
          dataLabels: {
            enabled: true,
            padding: 2,
            align: 'center',
            y: -10,
            allowOverlap: false,
            autoRotation: [-30, -20, 0, 20, 30],
            formatter: formatterFn,
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
            padding: 2,
            align: 'center',
            y: 0,
            x: 20,
            allowOverlap: false,
            autoRotation: [-30, -20, 0, 20, 30],
            formatter: formatterFn,
          },
        },
        line: {
          dataLabels: {
            enabled: true,
            padding: 2,
            align: 'center',
            y: -10,
            allowOverlap: false,
            autoRotation: [-30, -20, 0, 20, 30],
            formatter: formatterFn,
          },
        },
        area: {
          dataLabels: {
            enabled: true,
            padding: 2,
            align: 'center',
            y: -10,
            allowOverlap: false,
            autoRotation: [-30, -20, 0, 20, 30],
            formatter: formatterFn,
          },
        },
        bubble: { dataLabels: { enabled: true } },
        heatmap: { dataLabels: { enabled: true } },
        pie: {
          dataLabels: {
            enabled: true,
            padding: 2,
            align: 'center',
            y: -10,
            allowOverlap: true,
            autoRotation: [-30, -20, 0, 20, 30],
            formatter: formatPieFn,
            overflow: 'allow',
            stacking: false,
          },
        },
      },
      tooltip: {
        shared: !isXY && (!typeStacking || !settings.stackChartSeries),
      },
      xAxis: { type: this.getAxisType(categories, meta.x.attribute_name) },
      yAxis,
      series: series,
    };

    const valueTypes = isCSV
      ? calculationType
      : Object.keys(isXY ? raw?.[0]?.[0] || [] : raw?.[0] || []);
    const activeValueTypes = valueTypes.filter((valueType) =>
      calculationType.includes(valueType)
    );
    if (!isXY) {
      activeValueTypes.forEach((valueType) =>
        series.push(
          this.prepareSerieForValueType(
            valueType,
            getText(valueType),
            type,
            calculationType
          )
        )
      );
      filteredData.forEach((typed, i) => {
        series.forEach((serie) => {
          const simpleFormat = ['bubble', 'heatmap'].includes(
            this.props.chartType
          );
          const value = typed[serie.valueType];
          const canAdd =
            settings.hideEmpty && isNumeric(value)
              ? value > 0
              : isNumeric(value);
          const hasCategory =
            ['Tag', 'TagGroup', 'Grantee', 'Grant'].includes(meta.x.model) &&
            !this.props.settings.hideTagGroupLabel;

          const category = hasCategory
            ? flattenSingle(typed?.meta?.x) ?? categories[i]
            : flattenValue(typed?.meta?.x);

          canAdd &&
            serie.data.push(
              simpleFormat ? formatValue(value) : [category, formatValue(value)]
            );
        });
      });
    }

    if (isXY) {
      const seriesMap = {};
      const singleY =
        [...new Set(meta.y.attribute_values.map((el) => el.category))]
          .length === 1;

      meta.y.attribute_values.forEach((value) => {
        activeValueTypes.forEach((valueType) => {
          const hasCategory =
            ['Tag', 'TagGroup', 'Grantee', 'Grant'].includes(meta.y.model) &&
            !this.props.settings.hideTagGroupLabel;
          const category = singleY ? flatten(value) : flattenSingle(value);
          const categoryLabel = hasCategory ? category : flattenValue(value);

          seriesMap[category] ||= [];
          seriesMap[category][valueType] ||= this.prepareSerieForValueType(
            valueType,
            this.translateName(categoryLabel, meta.y.attribute_name),
            type,
            calculationType
          );
          if (
            !series.find((s) => s.name == seriesMap[category][valueType]?.name)
          )
            series.push(seriesMap[category][valueType]);
        });
      });

      filteredData.forEach((row) =>
        row.forEach((typed) =>
          calculationType.forEach((valueType) => {
            const category = flatten(typed?.meta?.y);
            const value = typed?.[valueType] || 0;
            const filteredValue =
              settings.hideEmpty && value === 0 ? null : value;
            seriesMap[category]?.[valueType]?.data.push(filteredValue);
          })
        )
      );

      const isSerieEmpty = (index) => {
        const matrix = series.map((d) =>
          Array.isArray(d?.data) ? d.data : []
        );
        const matRow = matrix.map((m) => m[index]);
        return matRow.every((v) => v === null) ?? false;
      };

      config.xAxis.categories = categories
        .map((label, i) =>
          settings.hideEmpty && isSerieEmpty(i) ? null : label
        )
        .filter((label, i, arr) =>
          label?.length > 1 ? arr.indexOf(label) == i : true
        );
    }

    if (this.props.chartType === 'pie') {
      const limit = settings.xAxisMin > 0 || settings.xAxisMax > 0;
      const start = limit && settings.xAxisMin > 0 ? settings.xAxisMin : 0;
      const endin = limit && settings.xAxisMax > 0 ? settings.xAxisMax : null;
      series.forEach((serie) => {
        if (!limit) return;
        serie.data = serie.data.slice(start, endin);
      });
    }

    const uniqueCategories = [...new Set(categories)];
    if (stack) {
      return transformChartToStacked(config, meta, settings, uniqueCategories);
    }
    if (this.props.chartType === 'heatmap') {
      return transformChartToHeatmap(config, meta, series, uniqueCategories);
    }
    if (this.props.chartType === 'bubble') {
      return transformChartToBubble(config, meta, uniqueCategories);
    }
    return config;
  }

  render() {
    return (
      <Chart
        key={[
          this.props.chartType,
          `sort-${this.props.chartsAxisData?.meta?.x?.model}`,
          `sort-${Boolean(
            this.props.chartType.split(':')[1] ||
              this.props.settings.stackChartSeries
          )}`,
          `sort-${Boolean(this.props.settings.hideEmpty)}`,
          `sort-${this.props.calculationType.length}`,
          `sort-${Boolean(this.props.settings.dataSorting)}`,
          `sort-${Boolean(this.props.settings.switchRowsAndColumns)}`,
          `sort-${Boolean(this.props.settings.hideTagGroupLabel)}`,
          `sort-${Boolean(this.props.settings.xAxisReversed)}`,
        ].join('-')}
        config={this.getConfig()}
        settings={this.props.settings}
      />
    );
  }
}
