import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uniq from 'ramda/src/uniq';
import { reduxForm, formValueSelector, submit } from 'redux-form';
import GrantSelector from 'src/components/GrantSelector/GrantSelector';
import { Section, Container, Actions, Button } from 'src/components/IMUI';
import { handleSubmitFail } from 'src/utils/validation';
import { getCountryNameByCode } from 'src/utils/countries';
import { formatMoney } from 'accounting';
import {
  findMatch,
  findMatchReport,
  findSurvey,
  flatProjectReports,
  flatten,
} from 'src/serializers/projectSerializer';
import { getOrgText, getText } from 'src/services/DictionaryService';
import { isAfter, isBefore, toLocaleDate } from 'src/utils/date';
import AnalysisInputs from 'src/components/AddProjectForm/AnalysisInputs';
import AnalysisTagCategoriesEdit from 'src/components/AddProjectForm/AnalysisTagCategoriesEdit';
import UploadCSV from 'src/pages/App/Grants/Import/UploadCSV';
import Dictionary from 'src/components/AddProjectForm/Dictionary';
import { Tabs } from 'src/components/IMUI/Tabs/Tabs';
import { canManageProject, surveyOnly } from 'src/userStorage';
import { bindActionCreators } from 'redux';
import cls from 'src/components/AddProjectForm/GrantSelectorForm.module.css';

const getPlural = (n) => (n > 1 ? `are ${n} items` : `is ${n} item`);
const grantValues = () => [
  { path: 'grantee.name', label: getText('Grantee') },
  { path: 'name', label: getText('Grant') },
  {
    path: 'start_date',
    width: 110,
    textAlign: 'right',
    label: 'Start date',
    type: 'date',
    customMatch: (a, b) => isAfter(a, b),
    valueTransform: toLocaleDate,
  },
  {
    path: 'end_date',
    width: 110,
    textAlign: 'right',
    label: 'End date',
    type: 'date',
    customMatch: (a, b) => isBefore(a, b),
    valueTransform: toLocaleDate,
  },
  {
    path: 'created_at',
    width: 110,
    textAlign: 'right',
    label: 'Created',
    isHiddenInFilters: true,
    defaultSort: true,
    valueTransform: toLocaleDate,
  },
  { path: 'funding_count', width: 100, label: 'Fundings', textAlign: 'right' },
  {
    path: 'total_funding',
    width: 100,
    label: 'Amount',
    textAlign: 'right',
    valueTransform: formatMoney,
  },
  { path: 'status', label: 'Status', width: 120 },
  {
    path: 'grant_country_code',
    label: 'Grant Country',
    valueTransform: getCountryNameByCode,
  },
  {
    path: 'grantee_country_code',
    label: 'Grantee Country',
    valueTransform: getCountryNameByCode,
  },
  { path: 'portfolio_list', label: 'Portfolios' },
  { path: 'issue_list', label: 'Issues' },
  { path: 'strategies_list', label: 'Strategies' },
  { path: 'population_list', label: 'Population of focus' },
  { path: 'cohort_list', label: 'Cohort' },
  { path: 'donor_list', label: 'Donors' },
  {
    path: 'reports_count',
    label: 'Reports',
    isHiddenInFilters: true,
    textAlign: 'right',
    width: 92,
  },
];
const reportTableValues = [
  { path: 'name', label: 'Report', defaultSort: true, ascending: true },
  { path: 'grantee_name', label: getText('Grantee') || getOrgText('Grantee') },
  { path: 'grant_name', label: getText('Grant') || getOrgText('Grant') },
  {
    path: 'date',
    label: 'Date',
    width: 110,
    type: 'date',
    customMatch: (a, b) => isBefore(a, b),
    valueTransform: toLocaleDate,
  },
  { path: 'report_type', label: 'Type' },
  { path: 'code', label: 'Code', width: 110 },
  { path: 'taggings_count', label: 'Taggings', width: 70, textAlign: 'right' },
];
const surveyTableValues = [
  { path: 'title', label: 'Survey', defaultSort: true, ascending: true },
  { path: 'status', label: 'Status' },
  {
    path: 'created_at',
    label: 'Created',
    width: 110,
    isHiddenInFilters: true,
    defaultSort: true,
    valueTransform: toLocaleDate,
  },
  { path: 'languages', label: 'Languages' },
  { path: 'public', label: 'Is Public' },
  { path: 'anonymous', label: 'Anonymous' },
  { path: 'batches', label: 'batches' },
  {
    path: 'meta.grantee_count',
    label: 'Recipients',
    width: 92,
    textAlign: 'right',
  },
  {
    path: 'meta.sent_surveys_count',
    label: 'Notified',
    width: 92,
    textAlign: 'right',
  },
  {
    path: 'meta.respondents_count',
    label: 'Respondents',
    width: 92,
    textAlign: 'right',
  },
  {
    path: 'meta.finished_surveys_count',
    label: 'Finished',
    width: 92,
    textAlign: 'right',
  },
];

export class GrantSelectorForm extends React.PureComponent {
  static propTypes = {
    project_uid: PropTypes.string,
    project_reports: PropTypes.array,
    project_surveys: PropTypes.array,
    selected_surveys: PropTypes.array,
    initial_project_surveys: PropTypes.array.isRequired,
    selected_projects: PropTypes.array.isRequired,
    dataSet: PropTypes.array.isRequired,
    reportSet: PropTypes.array.isRequired,
    surveySet: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    change: PropTypes.func,
    dirty: PropTypes.bool,
    skipPrompt: PropTypes.bool,
    grantee_type: PropTypes.string,
    submitButtonLabel: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onDeleteProject: PropTypes.func,
    noGrantAndGrantee: PropTypes.bool.isRequired,
    reset: PropTypes.func,
    submitSucceeded: PropTypes.bool,
  };

  static defaultProps = {
    formValues: {},
    grantee_type: undefined,
    project_uid: undefined,
    selected_projects: [],
    project_reports: [],
    initialValues: { project_reports: [] },
    selected_surveys: [],
    project_surveys: null,
    initial_project_surveys: [],
  };
  state = { activeTab: undefined };

  newIncluded = () =>
    this.props.dataSet.filter(
      (i) => this.isAddedGrants(i) && !this.prevAddGrants(i)
    );
  newExcluded = () =>
    this.props.dataSet.filter(
      (i) => this.prevAddGrants(i) && !this.isAddedGrants(i)
    );
  getDirtyMessage = () =>
    [
      'Are you sure you want to leave without saving?',
      this.props.selected_projects.length === 0
        ? ''
        : `\nThere ${getPlural(
            this.props.selected_projects.length
          )} selected but not changed`,
      this.newIncluded().length === 0
        ? ''
        : `\nThere ${getPlural(
            this.newIncluded().length
          )} added but not saved. \nClick Update button to save its changes`,
      this.newExcluded().length === 0
        ? ''
        : `\nThere ${getPlural(
            this.newExcluded().length
          )} removed but not saved. \nClick Update button to save its changes`,
    ]
      .join(' ')
      .trim();
  handleReset = () => {
    this.props.reset();
  };

  isAddedGrants = (entity) =>
    this.props.project_reports.some(findMatch(entity));
  prevAddGrants = (entity) =>
    this.props.initialValues.project_reports.some(findMatch(entity));
  isCheckGrants = (entity) =>
    this.props.selected_projects.some(findMatch(entity));
  selectedToAddGrants = () =>
    this.props.selected_projects.filter((el) => !this.isAddedGrants(el));
  selectedToRemGrants = () =>
    this.props.selected_projects.filter((el) => this.isAddedGrants(el));

  isAddedReport = (entity) =>
    this.props.project_reports.some(findMatchReport(entity));
  prevAddReport = (entity) =>
    this.props.initialValues.project_reports.some(findMatchReport(entity));
  isCheckReport = (entity) =>
    this.props.selected_projects.some(findMatchReport(entity));
  selectedToAddReport = () =>
    this.props.selected_projects.filter((el) => !this.isAddedReport(el));
  selectedToRemReport = () =>
    this.props.selected_projects.filter((el) => this.isAddedReport(el));
  handleAddRows = (el) => {
    this.props.change(
      'project_reports',
      uniq(this.props.project_reports.concat(flatProjectReports(el)))
    );
    this.clearSelection();
  };
  handleRemoveRows = (el) => {
    this.props.change(
      'project_reports',
      this.props.project_reports.filter(
        (pr) => !flatProjectReports(el).some(findMatch(pr))
      )
    );
    this.clearSelection();
  };
  handleRemoveReports = (el) => {
    this.props.change(
      'project_reports',
      this.props.project_reports.filter(
        (pr) => !flatProjectReports(el).some(findMatchReport(pr))
      )
    );
    this.clearSelection();
  };
  clearSelection = () => this.props.change('selected_projects', []);

  isAddedSurvey = (entity) =>
    this.props.project_surveys.some(findSurvey(entity));
  prevAddSurvey = (entity) =>
    this.props.initial_project_surveys.some(findSurvey(entity));
  isCheckSurvey = (entity) =>
    this.props.selected_surveys.some((s) => entity.id == s.id);
  selectedToAddSurvey = () =>
    this.props.selected_surveys.filter((el) => !this.isAddedSurvey(el));
  selectedToRemSurvey = () =>
    this.props.selected_surveys.filter((el) => this.isAddedSurvey(el));
  handleAddSurvey = (el) => {
    this.props.change(
      'project_surveys',
      uniq(
        this.props.project_surveys
          .concat(flatten(el))
          .map((s) => ({ id: Number(s.id) }))
      )
    );
    this.clearSelectionSurvey();
  };
  handleRemoveSurvey = (el) => {
    this.props.change(
      'project_surveys',
      this.props.project_surveys.filter(
        (pr) => !flatten(el).some(findSurvey(pr))
      )
    );
    this.clearSelectionSurvey();
  };
  clearSelectionSurvey = () => this.props.change('selected_surveys', []);

  handleToggleSelectProject = (entity, isChecked) =>
    isChecked
      ? this.props.change(
          'selected_projects',
          uniq(this.props.selected_projects.concat(flatten(entity)))
        )
      : this.props.change(
          'selected_projects',
          this.props.selected_projects.filter(
            (pr) => !flatten(entity).some(findMatch(pr))
          )
        );

  handleToggleSelectSurveys = (entity, isChecked) =>
    isChecked
      ? this.props.change(
          'selected_surveys',
          uniq(this.props.selected_surveys.concat(flatten(entity)))
        )
      : this.props.change(
          'selected_surveys',
          this.props.selected_surveys.filter(
            (pr) => !flatten(entity).some(findMatch(pr))
          )
        );

  renderTabGrants() {
    return (
      <GrantSelector
        type="grants"
        key="grants"
        tableValues={grantValues()}
        dictionaryPriority="project"
        data={this.props.dataSet}
        prevAdd={this.prevAddGrants}
        isAdded={this.isAddedGrants}
        isCheck={this.isCheckGrants}
        onAdd={this.handleAddRows}
        onRemove={this.handleRemoveRows}
        onToggleSelect={this.handleToggleSelectProject}
        onSelectedRowsReset={this.clearSelection}
        selectedToRemoveLength={this.selectedToRemGrants().length}
        selectedToAddLength={this.selectedToAddGrants().length}
        handleRemoveSelected={() =>
          this.handleRemoveRows(this.selectedToRemGrants())
        }
        handleAddedSelected={() =>
          this.handleAddRows(this.selectedToAddGrants())
        }
      />
    );
  }
  renderTabReports() {
    return (
      <GrantSelector
        type="reports"
        key="reports"
        tableValues={reportTableValues}
        dictionaryPriority="project"
        data={this.props.reportSet}
        isCheck={this.isCheckReport}
        prevAdd={this.prevAddReport}
        isAdded={this.isAddedReport}
        onAdd={this.handleAddRows}
        onRemove={this.handleRemoveReports}
        onToggleSelect={this.handleToggleSelectProject}
        onSelectedRowsReset={this.clearSelection}
        selectedToRemoveLength={this.selectedToRemReport().length}
        selectedToAddLength={this.selectedToAddReport().length}
        handleRemoveSelected={() =>
          this.handleRemoveReports(this.selectedToRemReport())
        }
        handleAddedSelected={() =>
          this.handleAddRows(this.selectedToAddReport())
        }
      />
    );
  }
  renderTabSurveys() {
    return (
      <GrantSelector
        type="surveys"
        key="surveys"
        tableValues={surveyTableValues}
        dictionaryPriority="project"
        data={this.props.surveySet}
        isCheck={this.isCheckSurvey}
        prevAdd={this.prevAddSurvey}
        isAdded={this.isAddedSurvey}
        onAdd={this.handleAddSurvey}
        onRemove={this.handleRemoveSurvey}
        onToggleSelect={this.handleToggleSelectSurveys}
        onSelectedRowsReset={this.clearSelectionSurvey}
        selectedToRemoveLength={this.selectedToRemSurvey().length}
        selectedToAddLength={this.selectedToAddSurvey().length}
        handleRemoveSelected={() =>
          this.handleRemoveSurvey(this.selectedToRemSurvey())
        }
        handleAddedSelected={() =>
          this.handleAddSurvey(this.selectedToAddSurvey())
        }
      />
    );
  }
  renderTabSettings() {
    return (
      <div>
        <h3 className={cls.titleTab}>Project Settings</h3>
        <AnalysisInputs
          onChange={(type) => this.props.change('grantee_type', type)}
          value={this.props.grantee_type}
        />
        <Dictionary
          title={'Dictionary'}
          hideGrant={this.props.noGrantAndGrantee}
        />
      </div>
    );
  }
  renderTabTags() {
    return (
      <div>
        <AnalysisTagCategoriesEdit />
      </div>
    );
  }
  renderImportTags() {
    return (
      <div>
        <UploadCSV
          {...this.props}
          title="Tag and Tag Group Import"
          target_type="Project"
          target_id={this.props.project_uid}
          type="Tag"
        />
      </div>
    );
  }

  onChangeActiveTab = (activeTab) => {
    if (this.state.activeTab == activeTab) return;
    this.setState({ activeTab });
  };
  availableTabs() {
    return [
      surveyOnly() || this.props.noGrantAndGrantee
        ? null
        : { value: 'grants', label: getOrgText('Grants') },
      surveyOnly() ? null : { value: 'reports', label: 'Reports' },
      { value: 'surveys', label: 'Surveys' },
      { value: 'tags', label: 'Active Tags' },
      { value: 'import', label: 'Import Tags' },
      { value: 'settings', label: 'Settings' },
    ].filter(Boolean);
  }

  defaultActiveIndex = () => {
    const index = this.availableTabs().findIndex(
      (t) =>
        t?.value == 'tags' && window.location.pathname?.endsWith(`/${t.value}`)
    );
    return index == -1 ? undefined : index;
  };

  render() {
    const activeTab =
      this.state.activeTab ||
      this.availableTabs()[this.defaultActiveIndex() || 0]?.value;
    const canDelete = [
      'grants',
      'reports',
      'surveys',
      'settings',
      'dictionary',
    ].includes(activeTab);
    return (
      <Section collapsed fullHeight>
        <Tabs
          isHeader
          hasFooter
          className={cls.tabs}
          tabContentClassName={cls.tabItem}
          onChange={this.onChangeActiveTab}
          defaultActiveIndex={this.defaultActiveIndex()}
        >
          {this.availableTabs().map((tab) => (
            <Tabs.Item key={tab.value} value={tab.value} label={tab.label}>
              {tab.value === activeTab &&
                activeTab === 'grants' &&
                this.renderTabGrants()}
              {tab.value === activeTab &&
                activeTab === 'reports' &&
                this.renderTabReports()}
              {tab.value === activeTab &&
                activeTab === 'surveys' &&
                this.renderTabSurveys()}
              {tab.value === activeTab &&
                activeTab === 'settings' &&
                this.renderTabSettings()}
              {tab.value === activeTab &&
                activeTab === 'tags' &&
                this.renderTabTags()}
              {tab.value === activeTab &&
                activeTab === 'import' &&
                this.renderImportTags()}
            </Tabs.Item>
          ))}
        </Tabs>

        {canManageProject() && canDelete && (
          <Section type="sticky-footer" secondary={false}>
            <Container horizontal>
              <Actions>
                <Button
                  size="l"
                  alert
                  secondary
                  label="Delete"
                  disabled={
                    this.props.dirty || this.props.selected_projects.length > 0
                  }
                  onClick={this.props.onDeleteProject}
                />
              </Actions>
              <Actions>
                {this.props.dirty && (
                  <Button
                    size="l"
                    alert
                    secondary
                    label="Reset Changes"
                    disabled={!this.props.dirty}
                    onClick={this.handleReset}
                  />
                )}
                <Button
                  size="l"
                  label={this.props.submitButtonLabel}
                  disabled={!this.props.dirty}
                  onClick={this.props.handleSubmit}
                />
              </Actions>
            </Container>
          </Section>
        )}
        <Prompt
          when={
            canManageProject() &&
            canDelete &&
            this.props.dirty &&
            !this.props.submitSucceeded &&
            !this.props.skipPrompt
          }
          message={this.getDirtyMessage()}
        />
      </Section>
    );
  }
}

export default connect(
  (state, props) => ({
    grantee_type: formValueSelector(props.form)(state, 'grantee_type'),
    selected_projects: formValueSelector(props.form)(
      state,
      'selected_projects'
    ),
    selected_surveys: formValueSelector(props.form)(state, 'selected_surveys'),
    project_reports: formValueSelector(props.form)(state, 'project_reports'),
    project_surveys:
      formValueSelector(props.form)(state, 'project_surveys') ||
      props.initial_project_surveys,
  }),
  (dispatch) => bindActionCreators({ submitForm: submit }, dispatch)
)(
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    onSubmitFail: (errors) => handleSubmitFail(errors),
  })(GrantSelectorForm)
);
