import React from 'react';
import { Container, Table, Tag } from 'src/components/IMUI';
import {
  SHORT_TEXT,
  LONG_TEXT,
  SINGLE_CHOICE,
  FINANCIAL,
  NUMERICAL,
  MULTIPLE_CHOICE,
  SINGLE_MATRIX,
  DATE,
  RATING,
  OPINION_SCALE,
  COUNTRY,
} from 'src/data/questionTypes';
import EmptyValue from './EmptyValue';
import QuotesList from 'src/pages/App/Analysis/TagEditor/components/Quotes';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import SingleMatrixAnswerTableValue from './SingleMatrixAnswerTableValue';

const renderEmpty = () => <EmptyValue key="empty" />;
const isNumeric = (v) => v != null && !isNaN(v) && isFinite(Number(v));

const castValue = (simpleValue) => {
  if (simpleValue == null || String(simpleValue).trim().length == 0)
    return null;
  return isNumeric(simpleValue) ? (
    <Tag key={simpleValue} dim label={simpleValue} />
  ) : (
    <span key={simpleValue}>{simpleValue}</span>
  );
};

export const AnswerTableQuote = ({ question, answer }) => {
  const totalSize =
    (answer.response?.simple_value || '').length +
    (answer.response?.other_value || '').length;
  const canQuote =
    totalSize > 0 &&
    [SHORT_TEXT, LONG_TEXT, SINGLE_CHOICE].includes(question.type);
  return canQuote ? (
    <QuotesList
      type="minimal"
      taggableType="survey_answers"
      taggableUid={answer.uid}
      selectedText={answer.response.simple_value || answer.response.other_value}
      selectionStart={question.position}
      selectionEnd={question.position}
    />
  ) : null;
};

const AnswersTableValue = ({ question, answer }) => {
  if (!answer?.response) return renderEmpty();

  // TODO SEB-188: RENDERING MULTIPLE_CHOICE SINGLE MATRIX
  // {"object_value"=>["A", "B", "C"]}
  switch (question.type) {
    case COUNTRY:
    case MULTIPLE_CHOICE:
      const multiAnswer = [
        ...new Set(
          Object.values(answer.response.object_value || {})
            .concat(
              answer.response.other_value
                ? `Other: ${answer.response.other_value}`
                : null
            )
            .filter((value) => value != null)
        ),
      ];
      return multiAnswer.length ? (
        <>
          {multiAnswer.map((value, i) => (
            <Tag dim key={`${value}-${i}`} label={value} />
          ))}
        </>
      ) : (
        renderEmpty()
      );

    case SINGLE_MATRIX:
      // TODO SEB-188: RENDERING MULTIPLE_CHOICE SINGLE MATRIX
      // {"object_value"=>{"A"=>[1, 3, 2], "B"=>[3, 2, 1]}},

      const getSingle = (row) =>
        answer.response.object_value?.[row.title]
          ? answer.response.object_value[row.title]
          : renderEmpty();

      if (
        question.type === SINGLE_MATRIX &&
        question.settings.singleMatrixColumns
      ) {
        return (
          <SingleMatrixAnswerTableValue question={question} answer={answer} />
        );
      }

      return answer.response.object_value != null ? (
        <TableComposition compact>
          <Table.Body>
            {question.matrixValues.map((row, i) => (
              <Table.Row key={`${row.title}-${i}`}>
                <Table.HCell text={row.title} />
                <Table.Cell text={getSingle(row)} />
              </Table.Row>
            ))}
          </Table.Body>
        </TableComposition>
      ) : (
        renderEmpty()
      );

    case DATE:
      return answer.response.simple_value != null ? (
        <Tag
          dim
          label={`${new Date(answer.response.simple_value).toDateString()}`}
        />
      ) : (
        renderEmpty()
      );

    case SHORT_TEXT:
    case LONG_TEXT:
    case SINGLE_CHOICE:
    case RATING:
    case FINANCIAL:
    case NUMERICAL:
    case OPINION_SCALE:
    default:
      const totalSize =
        (answer.response?.simple_value || '').length +
        (answer.response?.other_value || '').length;
      if (totalSize == 0) return renderEmpty();
      return (
        <Container>
          {castValue(answer.response.simple_value)}
          {answer.response.other_value && (
            <div>
              <br />
              Other: {castValue(answer.response.other_value)}
            </div>
          )}
        </Container>
      );
  }
};

export default AnswersTableValue;
