import { formatMoney } from 'accounting';
import { abbreviateNumber } from 'src/utils/number';

export const formatValue = (v) => Math.round(Number(v) * 100) / 100;

// when using watch out not to transform same founding_total multiple times by first formatting value and then formatting it again using formatValueLabel
export const formatValueLabel = (
  value,
  valueType,
  abbreviate,
  suffix,
  prefix
) => {
  if (value == undefined) return '';
  const isMoney = valueType === 'funding_total' || valueType === 'currency';
  if (isMoney) {
    return abbreviate
      ? `$${abbreviateNumber(formatValue(value || 0))}`
      : formatMoney(formatValue(value || 0), '$');
  }

  if (valueType?.includes('percentage')) suffix = '%';
  return `${prefix || ''}${
    abbreviate
      ? abbreviateNumber(formatValue(value || 0))
      : formatValue(value || 0)
  }${suffix || ''}`;
};
