import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import MUIChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import { T } from 'lioness';
import { Tag } from 'src/components/IMUI';
import { InputReduxForm, InputSticky, Label, Error } from './base';
import cls from './ChipInput.module.css';

const cx = classNames.bind(cls);

export const KEYCODE_TAB = 9;
export const KEYCODE_ENTER = 13;
export const KEYCODE_COMMA = 188;

const RenderTag = ({ text, handleRequestDelete, chipProps }, key) => (
  <Tag {...chipProps} label={text} onRemove={handleRequestDelete} key={key} />
);

RenderTag.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleRequestDelete: PropTypes.func,
  chipProps: PropTypes.object,
};

export const ChipInput = ({
  dataSource,
  dataSourceConfig,
  onChange,
  input = {},
  label,
  name,
  hintText,
  chipRenderer,
  chipProps,
  stick,
  meta,
  flat,
  border,
  borderDark,
  chipFullWidth,
  readOnly,
  canAddNewValues = true,
  chipsBottom = true,
  fullWidthInput = true,
  value = [],
  noValidation = false,
  underlineShow = false,
  newChipKeyCodes = [KEYCODE_ENTER, KEYCODE_COMMA, KEYCODE_TAB],
}) => {
  const [typeAhead, setTypeAhead] = useState('');
  const [chipValue, setChipValue] = useState(
    input?.onChange ? input.value : value
  );
  const innerRef = useRef();

  useEffect(() => {
    InputReduxForm.onChange?.(input, onChange)?.(chipValue);
  }, [onChange, chipValue]);

  const onRequestAdd = (chip) => {
    // chip when fired by newChipKeyCodes does not return proper id if element exists
    const inList = typeof chip === 'string' && dataSource.includes(chip);
    if (!canAddNewValues && !inList && !dataSourceConfig) return;

    const chipObjectSelected =
      (typeof chip === 'object' &&
        dataSource.find(
          (s) => s[dataSourceConfig?.text] === chip[dataSourceConfig?.text]
        )) ||
      chip;

    const chipObjectValue = dataSourceConfig?.value
      ? chipObjectSelected[dataSourceConfig.value]
      : chipObjectSelected;

    const isRepeated = chipValue.some((el) =>
      typeof el !== 'object'
        ? el === chipObjectSelected
        : el[dataSourceConfig?.value] === chipObjectValue
    );
    if (isRepeated) {
      return;
    }

    const newChipValue = [...chipValue, chipObjectValue];
    onChange?.(input);
    setChipValue(newChipValue);
  };

  const onKeyDownTab = (e) => {
    if (e?.key !== 'Tab') return;
    e.preventDefault();
    e.stopPropagation();
    if (typeAhead?.length == 0) return;
    if (typeAhead?.length > 0) {
      onRequestAdd(typeAhead[0]);
      setTypeAhead('');
      innerRef.current?.clearInput();
      return;
    }
  };

  const renderLabel = () => {
    if (label) return label;

    const hasEnter = newChipKeyCodes.includes(KEYCODE_ENTER);
    return (
      <span style={{ width: '100%' }}>
        <label style={{ fontSize: 11, float: 'right' }}>
          {hasEnter && (
            <span>
              <T
                message="hit {{ enter }} to include new"
                enter={<code>ENTER</code>}
              />{' '}
              {input?.name ?? name}.&nbsp;
            </span>
          )}
          <span>
            <code>TAB</code> <T>will add the first match from list</T>
          </span>
        </label>
      </span>
    );
  };

  const renderChips = (defaultChipProps, key) => {
    if (chipRenderer) return chipRenderer(defaultChipProps, key);
    if (!dataSourceConfig) {
      return RenderTag({ ...defaultChipProps, chipProps }, key);
    }

    const selectedChip = dataSource.find(
      (data) => data[dataSourceConfig.value] === defaultChipProps.chip
    );
    const selectedChipProps = {
      value: selectedChip[dataSourceConfig.value],
      text: selectedChip[dataSourceConfig.text],
    };

    return RenderTag(
      { ...defaultChipProps, chipProps, ...selectedChipProps },
      key
    );
  };

  const onRequestDelete = (chip, index) => {
    const newChipValue = [
      ...chipValue.slice(0, index),
      ...chipValue.slice(index + 1),
    ];
    onChange?.(input);
    setChipValue(newChipValue);
  };

  const onBlur = (ev) => {
    if (!input.onBlur) return;

    ev.preventDefault();
    input?.onBlur(input.value);
  };

  const onUpdateInput = (fieldInput, options) => {
    setTypeAhead(
      options.filter(
        (o) =>
          fieldInput &&
          o &&
          String(o)
            .toLocaleLowerCase()
            .includes(String(input).toLocaleLowerCase())
      )
    );
  };

  const onChipChange = () => {
    setTypeAhead('');
  };

  return (
    <div onKeyDown={onKeyDownTab}>
      <Label label={renderLabel()} />
      <MUIChipInput
        delayBeforeAdd
        ref={innerRef}
        autoComplete="off"
        role="presentation"
        dataSource={dataSource}
        dataSourceConfig={dataSourceConfig}
        underlineShow={underlineShow}
        hintText={hintText}
        fullWidthInput={fullWidthInput}
        openOnFocus
        fullWidth
        chipRenderer={renderChips}
        className={cx('imui-chip-input', 'imui-text-field', {
          [`imui-text-field-stick-${stick}`]: stick && stick.length,
          'imui-text-field-flat': flat,
          'imui-text-field-border': border,
          'imui-text-field-border-dark': borderDark,
          'imui-chip-input-has-hint': !!hintText,
          'imui-chip-input-chips-top': !chipsBottom,
          'imui-chip-input-chip-full-width': chipFullWidth,
        })}
        listStyle={{ width: '100%', maxWidth: '50vw' }}
        value={chipValue}
        onRequestAdd={onRequestAdd}
        onRequestDelete={onRequestDelete}
        onBlur={onBlur}
        newChipKeyCodes={newChipKeyCodes}
        menuStyle={{ maxHeight: 400, overflowY: 'auto' }}
        menuProps={{ className: cx('imui-input-dropdown') }}
        onUpdateInput={onUpdateInput}
        onChange={onChipChange}
        disabled={readOnly}
      />
      {!noValidation && <Error meta={meta} />}
    </div>
  );
};

ChipInput.propTypes = {
  value: PropTypes.array,
  label: PropTypes.node,
  chipsBottom: PropTypes.bool,
  noValidation: PropTypes.bool,
  onChange: PropTypes.func,
  chipRenderer: PropTypes.func,
  chipFullWidth: PropTypes.bool,
  canAddNewValues: PropTypes.bool,
  newChipKeyCodes: PropTypes.array,
  chipProps: PropTypes.object,
  flat: PropTypes.bool,
  border: PropTypes.bool,
  borderDark: PropTypes.bool,
  ...InputReduxForm.propTypes,
  ...InputSticky.propTypes,
};

export default ChipInput;
